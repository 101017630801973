import { graphql } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { renderDynamicComponents } from "../components";
import { getValue } from "../helper/translation";
import Breadcrumb from "../modules/breadcrumb";
import Layout from "../modules/layout";
import News from "../modules/news";
import SEO from "../modules/seo";

const SteeringCommittee = ({
  data: { allContentfulOrganization },
  pageContext,
}) => {
  const items = allContentfulOrganization.edges.map(edge => edge.node);
  const {
    name,
    contentComponents,
    node_locale,
    settings,
    breadcrumbs,
    showBreadcrumbNavigation,
    showNews,
  } = pageContext;
  const siteSettings = settings;
  const {microcopy} = siteSettings;

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${name}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <Breadcrumb
        visible={showBreadcrumbNavigation}
        breadcrumbs={breadcrumbs}
        locale={node_locale}
      ></Breadcrumb>
      <div className="steeringCommittee">
        {contentComponents &&
          renderDynamicComponents(contentComponents, node_locale)}
        <Container fluid as="section">
          <div className="steering-committee-table">
            <Row className="d-none d-sm-flex table-row">
              <Col sm={4} className="cell">
                <div className="cell-title">{getValue("labels.organization", microcopy)}</div>
              </Col>
              <Col sm={4} className="cell">
                <div className="cell-title">{getValue("labels.member", microcopy)}</div>
              </Col>
              <Col sm={4} className="cell">
                <div className="cell-title">{getValue("labels.deputy", microcopy)}</div>
              </Col>
            </Row>
            {items
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map(item => (
                <div className="bordered">
                  <Row className="table-row table-row--content">
                    <Col sm={4} className="cell">
                      <div className="cell-title cell-title--xs">
                        Mitgliedsorganisation
                      </div>
                      <div className="cell-content">
                        {item.fullName || item.name}
                      </div>
                    </Col>
                    <Col sm={4} className="cell">
                      {item.member && (
                        <>
                          <div className="cell-title cell-title--xs">
                            Mitglied
                          </div>
                          <div className="cell-content">
                            {item.member.name}
                            <br />
                            {item.member.position}
                          </div>
                        </>
                      )}
                    </Col>
                    <Col sm={4} className="cell">
                      {item.representation && (
                        <>
                          <div className="cell-title cell-title--xs">
                            Stellvertretung
                          </div>
                          <div className="cell-content">
                            {item.representation.name}
                            <br />
                            {item.representation.position}
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
          </div>
        </Container>
        <News
          node_locale={node_locale}
          settings={settings}
          visible={showNews}
        />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($node_locale: String!) {
    allContentfulOrganization(
      filter: {
        node_locale: { eq: $node_locale }
        isSteeringCommittee: { eq: true }
      }
    ) {
      edges {
        node {
          name
          fullName
          member {
            name
            position
          }
          representation {
            name
            position
          }
        }
      }
    }
  }
`;

export default SteeringCommittee;
