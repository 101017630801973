import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getLocalizedLink } from "../helper/link";

const Breadcrumb = ({ visible, breadcrumbs, locale }) => {
    return visible ? (
        <Container fluid className={"my-4"}>
            <Row>
                <Col xs={12}>
                    <ul className={"breadcrumb-navigation"}>
                        {breadcrumbs.map(breadcrumb => (
                            <li className={"breadcrumb-navigation-item"}>
                                <a href={getLocalizedLink(`/${breadcrumb.slug}`, locale)}>{breadcrumb.name}</a>
                            </li>
                        ))}
                    </ul>

                </Col>
            </Row>
        </Container>
    ) : ""
};

Breadcrumb.propTypes = {
    visible: PropTypes.bool,
    breadcrumbs: PropTypes.array,
    locale: PropTypes.string
};
Breadcrumb.defaultProps = {
    visible: false,
    breadcrumbs: [],
    locale: 'de-DE'
};

export default Breadcrumb;
